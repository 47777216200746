import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

export default function Panel({
	page,
	opening,
	screenWidth
}) {
	const [ pointEl, setPointEl ] = useState({})
	const navigate = useNavigate()
	
	const copyIp = () => {
		navigator.clipboard.writeText('play.awzone.net')
	}
	
	const openDiscord = () => {
			window.open("https://discord.gg/MC6ccff", "mozillaTab");
	}
	
	const goTo = route => {
		navigate(route)
	}
	
	function MenuBtn({ minWidth, disabled, name, route, routes, action }) {
		if(minWidth > screenWidth) return <></>
		
		const isOpening = routes?.find(x => x === opening)
		const isSelected = routes?.find(x => x === page)
		const selected = isOpening || isSelected && !opening
		
		return (<motion.div
			onClick={ disabled ? null : () => { action ? action() : goTo(route) } }
			style={disabled ? { opacity: 0.7 } : {}}
			animate={ selected ? { color: '' } : { color: '#fef' } }
			whileHover={{
				scale: 1
			}}
			whileTap={{
				scale: 1
			}}
			className={`btn-main`}
			id={ selected ? 'menu-active' : '' }
			>
			<a>{ name }</a>
		</motion.div>)
	}
	
	useEffect(() => {
		const element = document.getElementById('menu-active')
		const position = element ? element.getBoundingClientRect() : null
		if(!position) return;
		setPointEl(position)
	}, [ screenWidth, opening, page ])
	
	const value = (pointEl.x + pointEl.width / 2 - 2).toFixed()
	
	return (
	<div className="panel">
				<div className="menu">
					<motion.div
					style={ !pointEl ? {} : { top: '10px' } }
					animate={screenWidth < 700 ? { display: 'none' } :
							 value ? { left: value + 'px', opacity: 1 } : {} }
					transition={{ ease: [0.1, 0, 0, 1], duration: 0.4 }}
					className="swimming-point"/>
					<MenuBtn name="Главная" route="/" routes={["feed"]} />
					<MenuBtn name="Форум" route="/forum" routes={["sections","topic","section"]} />
					<MenuBtn name="Репорты" disabled={true} />
					<MenuBtn name="Записи" disabled={true} />
					<MenuBtn name="Discord" minWidth={600} action={openDiscord} />
				</div>
				<div className="get-ip">
					<div className="get-ip-clickable" onClick={ copyIp }>
						<a className="click-to-copy">Нажми, чтобы скопировать</a>
						<a className="server-ip">play.awzone.net</a>
					</div>
				</div>
			</div>
	)
}