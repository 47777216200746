import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { motion, animate } from "framer-motion"
import API from '../API.js'

import Form from '../components/Form'

import { Forward, Back } from '../components/Icons'

import './Auth.css'

export default function Auth({ 
		setSpecial,
		openMain,
		openRegister,
		openLogin,
		setWrong,
		wrong,
		setAuthorized,
		search, setSearch
}) {
	const [ loading, setLoading ] = useState('stale')
	const [ authRouteText, setRouteText ] = useState("Вход в личный кабинет")
	
	const [ passwordInputType, setPasswordInputType ] = useState('password')
	const [ passwordAnimation, setPasswordAnimation ] = useState(false)
	
	const [ password, setPassword ] = useState('')
	const [ name, setName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ code, setCode ] = useState('')
	
	const [ selected, setSelected ] = useState(null)
	
	const [ codeReq, setCodeReq ] = useState(false)
	
	const route = search.get("route")
	
	useEffect(() => {
		if(!route) return
		
		setTimeout(() => {
			setPassword("")
			setName("")
			setEmail("")
			setCode("")
			setPasswordInputType("password")
		}, 500)
		
		if(route === 'login') setRouteText('вход в личный кабинет'.toUpperCase())
		else if(route === 'signin') setRouteText('создание аккаунта'.toUpperCase())
		
		return () => {}
	}, [ route ])
	
	useEffect(() => {
		if(!authRouteText) return
		
		const sheets = document.styleSheets;
		const selector = ".auth-route-text a::before";
		for (let sheet of sheets) {
			if(sheet.href && !sheet.href.match("https://www.awzone.net/")) continue
			for (let rule of sheet.cssRules) {
				if (rule.selectorText === selector) {
				rule.style["content"] = '"' + authRouteText + '"';
				}
			}
		}
	}, [authRouteText])
	
	const login = async () => {
		setWrong(null)
		if(loading !== "stale") return
		if(email === '' || password === '') return setWrong("Не все поля заполнены!")
		
		setLoading('loading')
		
		const response = await API.login(email, password)
		
		setLoading('stale')
		
		if(response.user === "not-found") {
			return setWrong("Неверная почта или пароль!")
		} else if(!response.success) {
			return setWrong("Ошибка! Напишите разработчику")
		}
		
		localStorage.setItem('refreshToken', response.tokens.refreshToken)
		localStorage.setItem('token', response.tokens.accessToken)
		localStorage.setItem('accessTokenExpires', response.tokens.accessTokenExpires)
		localStorage.setItem('profile', JSON.stringify(response.details))
		localStorage.setItem('name', response.details.name)
		localStorage.setItem('userId', response.details.id)
		setAuthorized(true)
		
		openMain()
	}
	
	const startRegister = async () => {
		setWrong(null)
		if(loading !== "stale") return
		if(name === '' || password === '' || email === '') return setWrong("Не все поля заполнены!")
		
		setLoading('loading')
		
		const response = await API.register(name, password, email)
		
		setLoading('stale')
		
		console.log(response)
		
		if(response.email === "content") {
			return setWrong("Укажите настоящую почту!")
		} else if(response.email === "not-unique") {
			return setWrong("Почта занята!")
		} else if(response.code) {
			return setWrong("Подождите еще: " + response.left + "с.")
		} else if(!response.success) {
			return setWrong("Ошибка! Напишите разработчику")
		}
		
		setCodeReq(true)
	}
	
	const completeRegister = async () => {
		setWrong(null)
		if(loading !== "stale") return
		if(email === '') return setWrong("Начните заного!")
		if(code === '') return setWrong("Не все поля заполнены!")
		
		const eula = document.getElementById('agree-check')
		const mailing = document.getElementById('mailing-check')
		console.log(eula)
		if(!eula.checked) return setWrong("Вы должны ознакомиться с правилами")
		
		setLoading('loading')
		
		const response = await API.completeRegister(email, code, eula.checked, mailing.checked)
		
		setLoading('stale')
		
		if(response.code === "wrong") {
			return setWrong("Неверный код!")
		} if(response.code === "expired") {
			return setWrong("Код неактивен, начните заного!")
		} else if(!response.success) {
			return setWrong("Ошибка! Напишите разработчику")
		}
		
		localStorage.setItem('refreshToken', response.tokens.refreshToken)
		localStorage.setItem('token', response.tokens.accessToken)
		localStorage.setItem('accessTokenExpires', response.tokens.accessTokenExpires)
		localStorage.setItem('profile', JSON.stringify(response.details))
		localStorage.setItem('name', response.details.name)
		localStorage.setItem('userId', response.details.id)
		setAuthorized(true)
		
		openMain()
	}
	
	const recovery = () => {
		setSpecial('enabled')
	}
	
	console.log(route !== 'signin' && route !== 'login')
	
	return (<div
	style={ route !== 'signin' && route !== 'login' ? { display: 'none' } : { display: 'flex' } }
	className="auth">
		<motion.div className="panel panel-login"
		onKeyDown={ e => { if(e.key === 'Enter') login() } }
		>
			<motion.a
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9, transition: { duration: 0.1 } }}
			className="back" onClick={ openMain }><Back/>&nbsp; Вернуться</motion.a>
			
			<a className="error-text">{ wrong }</a>
			
			<div className="fields">
				<Form type='login' set={setEmail} get={email} selected={selected} setSelected={setSelected} />
				<Form type='password' set={setPassword} get={password} selected={selected} setSelected={setSelected} />
				
				<div className="pass-recovery clickable">&nbsp;Забыли пароль?</div>
				
				<motion.div
				whileHover={{ opacity: 1 }}
				whileTap={{ scale: 0.9 }}
				className="continue login" onClick={ login }><a>Войти</a></motion.div>
			</div>
			
			<div className="reg">Нет аккаунта?
			<a onClick={ openRegister } className="clickable"> 
			Зарегистрироваться</a></div>
		</motion.div>
		
		{ !codeReq ? (<motion.div className="panel panel-register"
		onKeyDown={ e => { if(e.key === 'Enter') startRegister() } }>
			<motion.a
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9, transition: { duration: 0.1 } }}
			className="back" onClick={ openMain }><Back/>&nbsp; Вернуться</motion.a>
			
			<a className="error-text">{ wrong }</a>
			
			<div className="fields">
				<Form type='name' set={setName} get={name} selected={selected} setSelected={setSelected} />
				<Form type='password' set={setPassword} get={password} selected={selected} setSelected={setSelected} />
				<Form type='email' set={setEmail} get={email} selected={selected} setSelected={setSelected} />
				
				<motion.div
				whileHover={{ scale: 1 }}
				whileTap={{ scale: 0.95 }}
				className="continue" onClick={ startRegister }><a>Продолжить</a></motion.div>
				
			</div>
			
			<div className="reg">Уже есть аккаунт? <a onClick={ openLogin } className="clickable">Войти</a></div>
		</motion.div>) : (<motion.div className="panel panel-register">
			{/* Поле завершения регистрации */}
			<motion.a
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9, transition: { duration: 0.1 } }}
			className="back" onClick={ () => setCodeReq(false) }>
			<Back/>&nbsp; Вернуться</motion.a>
			<a className="error-text">{ wrong }</a>
			<div className="verify-text">
				<a>На почту <a style={{ color: '#FFF7B2' }}>{ email } </a>
				отправлен код для завершения регистрации, укажите его в поле ниже</a>
				<a>Если кода нет, проверьте папку "Спам" или попробуйте снова</a>
			</div>
			<div className="fields">
				<Form type='code' set={setCode} get={code} selected={selected} setSelected={setSelected} />
				
				<div className="checkbox"><motion.input
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95, opacity: 0.8 }}
				id="agree-check"
				type="checkbox"/><div
				onClick={ e => { const t = e.target.previousSibling; t.checked = !t.checked } }
				>Я ознакомлен с <a style={{ color: "#7DFFEF", cursor: 'pointer' }}>Правилами проекта</a></div></div>
				<div
				className="checkbox"><motion.input
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95, opacity: 0.8 }}
				id="mailing-check"
				type="checkbox"/><a
				onClick={ e => { const t = e.target.previousSibling; t.checked = !t.checked } }
				>Хочу получать рассылку и новости</a></div>
				
				<motion.div
				whileHover={{ opacity: 1 }}
				whileTap={{ scale: 0.95 }}
				style={{ width: '120px' }}
				className="continue" onClick={ completeRegister }><a>Далее</a></motion.div>
				
			</div>
		</motion.div>) }
		
		
	</div>)
}