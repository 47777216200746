import { useState, useMemo } from 'react'
import { motion, animate } from "framer-motion"

import Eye from '../components/Eye.svg'
import Info from '../components/Info.svg'

export default function Form({ type, set, get, selected, setSelected }) {
	const [ shown, setShown ] = useState(false)
	const [ inputType, setInputType ] = useState(type === 'password' ? 'password' : 'text')
	const [ animation, setAnimation ] = useState(false)
	
	const gradientId = useMemo(() => 'gradient_' + Math.ceil(Math.random() * 999999))
	
	const animateGradient = colors => {
		animate('#' + gradientId, 
				{ background: 'linear-gradient(to right, ' + colors + ')'},
				{ duration: 0.3 })
	}
	
	const showPassword = () => {
		if(animation) return
		
		const item = document.getElementById(gradientId)
		
		animate(item.nextSibling, { opacity: 0, duration: 0.15 })
		
		setTimeout(() => {
			if(inputType === 'password')
				setInputType('text')
			else setInputType('password')
			
			console.log(item.nextSibling)
			
			animate(item.nextSibling, { opacity: 1 }, { duration: 0.15 })
			setAnimation(false)
		}, 150)
		
		setAnimation(true)
	}
	
	const text = type === 'password' ? 'Пароль' :
				 type === 'name' ? 'Видимое имя' :
				 type === 'login' ? 'Почта или никнейм' :
				 type === 'email' ? 'Почта' :
				 type === 'code' ? 'Код подтверждения' : ''

	return (<><a className="field-title">{ text }</a>
	<div style={{ marginBottom: '20px' }}>
		<motion.div
		onMouseEnter={e => {
			if(selected === e.target) return
			animateGradient('#ac8fff, #f579ff')
		}}
		onMouseLeave={e => {
			if(selected === e.target) return
			animateGradient('#fff, #fff')
		}}
		onSelect={e => {
			console.log(e)
			setTimeout(() => setSelected(e.target, 50))
			animateGradient('#794AFF, #EB00FF')
		}}
		onBlur={e => {
			setSelected(null)
			animateGradient('#fff, #fff')
		}}
		className="form">
			<div id={gradientId} className="gradient"/>
			<input
			type={ inputType }
			value={get} onChange={e => set(e.target.value)} className="field"/>
		
		{ type === 'password' ? (<motion.img 
		whileHover={{ opacity: 1, scale: 1.05 }}
		animate={inputType === 'text' && !animation ? { opacity: 1 } : {}}
		onClick={ showPassword } src={Eye} className="password-peek"/>) : '' }
		
		{ type === 'email' ? (<><motion.img 
		whileHover={{ opacity: 1, scale: 1.05 }}
		whileTap={{ scale: 0.9, duration: 0 }}
		
		onMouseEnter={() => {
			animate('.email-info-text', { display: 'flex', opacity: 1 })
		}}
		onMouseLeave={() => {
			animate('.email-info-text', { display: 'none', opacity: 0 })
		}}
		
		src={Info} className="email-info"/>
		<div className="email-info-text">
			<a>На почту будет отправлен код верификации</a>
			<a>Для того, чтобы писать на форуме, вам потребуется подтвердить ее!</a>
		</div></>) : '' }
		
		</motion.div>
	</div></>)
}