import './SearchBar.css'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { motion } from "framer-motion"
import API from '../../API.js'

import Search from './img/search.png'

export default function SearchBar({ authorized, animate, navigate, state, page }) {
	const visible = page === 'sections' || page === 'section' ||
					page === 'creation' || page === 'topic'
	
	const [ results, setResults ] = useState(null)
	
	const input = document.getElementById("search")
	
	if(!authorized) return <></>
	
	return (<motion.div
	id="search-block"
	initial={{ opacity: 0, display: 'none' }}
	animate={ state === 'none' && visible ? { opacity: 1, display: 'flex', marginBottom: '15px' } : { opacity: 0, marginBottom: 0 } }
	onClick={e => {
		if(e.target.id !== 'search-block') return;
		e.target.querySelector("#search").focus()
	}}
	className="search-bar">
		<img draggable="false" src={Search}/>
		<div className="input">
			<div id="placeholder">Поиск по форуму</div>
			<input
			id="search"
			onFocus={e => {
				const placeholder = e.target.parentElement.querySelector("#placeholder")
				const hints = document.body.querySelector("#hints")
				
				if(e.target.value) animate(hints, { opacity: 1, display: 'flex' }, { duration: 0.3 })
				animate(placeholder, { marginBottom: '10px', opacity: 0 }, { duration: 0.2 })
				//animate(e.target.parentElement, { scale: 1 })
			}}
			onBlur={e => {
				const placeholder = e.target.parentElement.querySelector("#placeholder")
				const hints = document.body.querySelector("#hints")
				
				if(!e.target.value) animate(placeholder, { marginBottom: 0, opacity: 1 }, { duration: 0.3 })
				animate(hints, { opacity: 0, display: 'none' }, { duration: 0.3 })
			}}
			onChange={e => {
				const hints = document.body.querySelector("#hints")
				
				const value = e.target.value
				
				setTimeout(() => {
					if(value === e.target.value) {
						API.search(value).then(data => {
							console.log(data)
							console.log('wat')
							setResults(data)
						})
					}
				}, 500)
				
				if(value) animate(hints, { opacity: 1, display: 'flex' }, { duration: 0.3 })
				else animate(hints, { opacity: 0, display: 'none' }, { duration: 0.3 })
			}}
			/>
		</div>
		<div id="hints" className="hints">
		{
			results !== null ? results.length ? results.map(result => {
				console.log(result)
				const inner = result.text.replace(new RegExp(input.value, 'gi'), '<div class="bold">' + input.value + '</div>')
				return <motion.div 
					whileHover={{ marginLeft: '25px' }}
					onClick={e => {
						e.preventDefault()
						navigate(`/forum/topic/${result.topicId}${result.title ? ('-' + result.title.toLowerCase().replace(/ /g,'-')) : ''}`)
						window.location.reload()
					}}
					className="result"
					dangerouslySetInnerHTML={{__html: inner}}/>
			})  : <div className="result">Ничего не найдено!</div> 
				: <div className="result">В поисках...</div>
		}
		</div>
		
	</motion.div>)
}