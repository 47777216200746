import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { motion } from "framer-motion"
import API from '../API.js'

import { Timer } from '../Time'

import { Input } from './components/Field'
import TopicPost from './components/TopicPost'

import HoverableSections from './components/HoverableSections'
import { Months, ParseTime } from '../Time.js'


import './Topic.css'
import Arrow from './components/img/arrow.svg'


// Проверка, виден ли элемент
const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )

function hideOnClickOutside(element, animate, setOpenedActions) {
	const outsideClickListener = event => {
		if (!element.contains(event.target) && isVisible(element)) { // or use: event.target.closest(selector) === null
		  const id = element.id.replace('actions-block-', '')
		  setOpenedActions(null)
		  animate('#actions-icon-' + id, { rotate: 0 })
		  animate(element, { opacity: 0, display: 'none', marginTop: '-20px' })
		  removeClickListener();
		}
	}
	
	const removeClickListener = () => {
		document.removeEventListener('click', outsideClickListener);
	}
	
	document.addEventListener('click', outsideClickListener);
}




export default function Topic({ 
	page,
	animate,
	forum, 
	setRoute, 
	route,
	screenWidth,
	online,
	openLogin,
	authorized
}) {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const navigate = useNavigate()
	const location = useLocation()
	
	const pathname = useMemo(() => location.pathname.split('/'), [])
	const parts = pathname[3]?.split('-')
	console.log(location)
	console.log(parts)
	const id = +parts?.[0];
	
	const [ loaded, setLoaded ] = useState(false)
	const [ posts, setPosts ] = useState([])
	const [ postsElement, setPostsElement ] = useState(<></>)
	const [ text, setText ] = useState("")
	const [ symbols, setSymbols ] = useState(0)
	
	const [ topic, setTopic ] = useState(id) // !!!
	const [ title, setTitle ] = useState("")
	const [ pageNum, setPageNum ] = useState(+searchParams.get("page"))
	const [ pages, setPages ] = useState(null)
	const [ visible, setVisible ] = useState([])
	
	const [ section, setSection ] = useState({})
	
	const [ actionsOpenedFor, setOpenedActions ] = useState(null)
	const [ editFor, setEditFor ] = useState(null)
	const [ editText, setEditText ] = useState(null)
	
	const [ themeOnline, setThemeOnline ] = useState(null)
	const [ initial, setInitial ] = useState(false)
	const [ updateInput, setUpdateInput ] = useState(false)
	
	const [ timer, setTimer ] = useState(localStorage.getItem("send-limit") !== null)
	
	
	 
	useEffect(() => {
		API.open(id, pageNum).then(response => {
			console.log(response)
			if(response.topic === "wrong") return navigate("/forum")
			setPages(response.pages)
			setPosts(response.posts)
			setTitle(response.title)
			
			setSection(forum.sections[response.posts?.[0]?.section])
		})
		
		return () => {}
	}, [])
	
	useEffect(() => {
		if(!online || !posts.length) return
		const users = online.users.filter(x => x.href === 'topic-' + posts[0].topicId)
		const guests = online.guests.filter(x => x.href === 'topic-' + posts[0].topicId).length
		setThemeOnline({ users, guests })
	}, [online, posts])
	
	/* Отправка ответа */
	const send = async() => {
		if(!authorized) return
		const { topicId } = posts[0]
		const response = await API.send(topicId, text)
		
		console.log(response)
		if(!response?.posts?.length) return
		
		setInitial(true)
		setSymbols(0)
		setText("")
		
		localStorage.setItem("send-limit", Date.now() + 60000)
		setTimer(true)
		
		//setTimeout(() => { setUpdateInput(false) }, 200)
		
		localStorage.removeItem('reply-' + topicId)
		setPages(response.pages)
		
		if(response.pages !== pageNum) {
			setPosts([])
			await new Promise(r => { setTimeout(r, 300) })
		}
		
		setPosts(response.posts)
		setSearchParams('page=' + response.pages)
		setPageNum(response.pages)
	}
	/* -------------- */
	
	const open = async _page => {
		document.getElementsByClassName("page")[0].scrollTo({ top: 300, behavior: 'smooth' })
		if(pageNum === _page) return;
		const response = await API.open(topic, _page)

		console.log(response)
		setPages(response.pages)
		setPosts(response.posts)
		setPageNum(_page)
		
		setSearchParams("page=" + _page)
	}
	
	const PageBtn = ({ condition, num }) => {
		if(condition === undefined || condition) return (
			<a onClick={e => { 
				e.preventDefault()
				open(num)
			}}
			href={location.pathname + "?page=" + num}
			className="page-btn">{num}</a>)
	}
	
	useEffect(() => {
		setPostsElement(posts.map((post, index) => 
			TopicPost({
			editFor,
			editText,
			setEditText,
			sendEdit,
			online,
			authorized, 
			reportPost, 
			editPost, 
			deletePost, 
			switchActions, 
			animate, 
			index, 
			post })))
	}, [posts, editFor, editText])
	
	const switchActions = e => {
		console.log(e)
		const id = e.target?.id?.replace('actions-icon-', '') ?? e
		
		if(actionsOpenedFor !== null && document.getElementById("#actions-icon-" + actionsOpenedFor)) {
			animate('#actions-icon-' + actionsOpenedFor, { rotate: 0 })
			animate('#actions-block-' + actionsOpenedFor, { display: 'none', opacity: 0, marginTop: '-10px' }, { duration: 0.5 })
		}
		
		if(actionsOpenedFor !== id) {
			animate(e.target, { rotate: 180 })
			animate(`#actions-block-${id}`, { display: 'flex', opacity: 1, marginTop: '10px' }, { duration: 0.5 })
			setOpenedActions(id)
			
			hideOnClickOutside(document.getElementById('actions-block-' + id), animate, setOpenedActions)
		} else setOpenedActions(null)
	}
	
	/* Редактирование записей */
	
	const editPost = id => {
		if(id === null) {
			localStorage.removeItem('edit')
			setEditFor(null)
			return;
		}
		
		console.log(posts)
		console.log(id)
		localStorage.setItem('edit', JSON.stringify(posts.find(x => x.id === id).content))
		setEditFor(id)
		setOpenedActions(null)
	}
	
	console.log('outside ' + editText)
	const sendEdit = async () => {
		console.log('inside ' + editText)
		const response = await API.edit(editFor, editText)
		
		if(!response.success) return
		
		const post = posts.find(x => x.id === editFor)
		
		post.content = JSON.parse(editText)
		post.edit = { at: Date.now() / 1000, total: post.edit ? (post.edit.total + 1) : 1 }
		
		editPost(null)
	}
	
	/* Удаление записи */
	
	const deletePost = async id => {
		console.log(id)
		
		const response = await API.hide(id)
		
		console.log(response)
		console.log(posts)
		
		if(response.topic_hidden) {
			navigate(`/forum/section/${posts[0].section}`)
		}
		else if(response.hidden) {
			console.log(posts)
			console.log(posts.filter(x => x.id !== id))
			setPosts(posts.filter(x => x.id !== id))
		}
	}
	
	const reportPost = id => {
		
	}
	
	const sectionURI = ("/forum/" + (section?.id+1) + '-' + section?.name).replace(/ /g,'-').toLowerCase()
	
	const [ hoverUps, setHoverUps ] = useState([[],[],[],[]])
	
	const _section = forum.sections[posts?.[0]?.section]
	const theme = forum.themes[_section?.theme]
	
	
	
	
	if(page !== 'topic') return <></>
	
	return (
	<div className="flex-column">
		<motion.div
		initial={{ opacity: 0 }}
		animate={theme && posts?.[0] ? { opacity: 1 } : {}}
		// Во избежание появления до загрузки
		className="section-bar">
		{
			<HoverableSections forum={forum} array={[
				[ "/forum", "Разделы" ],
				[ "/forum", theme?.name ],
				[ sectionURI, _section?.name ],
				[`/forum/topic/${posts[0]?.topicId}-${title.replace(/ /g,'-').toLowerCase()}`, title]
			]}/>
		}
		</motion.div>
	
	{
		postsElement
	}
	
	{
		posts.length ? (
	<motion.div 
	initial={{ opacity: 0 }}
	animate={{ opacity: 1 }}
	exit={{ opacity: 0 }}
	transition={{ duration: 0.2, delay: posts.length * 0.1 }}
	className="interact">
		<a className="reply-title outbox-text">Создание ответа</a>
		
		<motion.div className="editor">
		{
			<Input
			textLocalStorage={'reply-' + posts[0].topicId}
			initial={initial}
			setInitial={setInitial}
			openLogin={openLogin}
			isAuthorized={authorized}
			topicId={posts[0].topicId}
			setText={setText}
			maxSymbols={2000}
			setSymbols={setSymbols} />
		}
		</motion.div>
		<div className="preview" style={authorized ? {} : {}}>
			<a className="edit-text">{ authorized ? 'Редактирование' : 'Необходим вход' }</a>
			<div className="right">
				<a className="symbols">{
					timer ? <><a>Подождите задержку: </a><Timer name="send-limit" setTimer={setTimer}/></>
					: `Осталось символов: ${ 2000 - symbols }`
				}</a>
				<motion.div className="send"
				whileHover={{ scale: 1.02 }}
				whileTap={{ scale: 0.96 }}
				onClick={send}
				><a>Отправить</a></motion.div>
			</div>
		</div>
		
		<div className="go-section light-back-line">
			<a onClick={
				e => {
					e.preventDefault()
					navigate(sectionURI)
				}
			}
			href={sectionURI} className="outbox-text">{"← К списку тем"}</a>
			<div className="page-select">
			{
				(() => {
					console.log(pageNum)
					if(pageNum < 2) {
						return (
						<>
							<PageBtn num={1}/>
							<PageBtn condition={pages >= 2} num={2} />
							<PageBtn condition={pages >= 3} num={3} />
							<PageBtn condition={pages >= 4} num={pages}/>
						</>
						)
					} else {
						return (
						<>
							<PageBtn condition={pages > 2} num={1}/>
							<PageBtn condition={pages > 3} num={pageNum - 2}/>
							<PageBtn num={pageNum - 1}/>
							<PageBtn num={pageNum}/>
							<PageBtn condition={pages > pageNum} num={pageNum + 1}/>
							<PageBtn condition={pages > (pageNum + 1)} num={pages}/>
						</>
						)
					}
				})()
			}
			</div>
		</div>
		{ themeOnline ? (
		<div className="watchers">
			<a className="outbox-text">Тему просматривают</a>
			<div className="watchers-content">
				<div>
				<a>{ themeOnline.users.length ? themeOnline.users.map(x=>x.name).join(", ") : 'Никто' }
				, а также <a style={{fontWeight:'600'}}>{themeOnline.guests}</a> гостей</a>
				</div>
			</div>
		</div>
		) : '' }
	</motion.div>
	) : '' }
	
	</div>)
}