import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { motion } from "framer-motion"
import API from '../API.js'

import InfoBlock from './components/InfoBlock.js'
import HoverableSections from './components/HoverableSections'

import { ParseTime } from '../Time'

import './Forum.css'
import './Sections.css'

import Discord from './img/Discord.svg'
import Telegram from './img/Telegram.svg'
import VK from './img/VK.svg'

import ChatIcon from './components/img/message.svg'

const item = {
	hidden: { marginTop: '140px', opacity: 0 },
	visible: { marginTop: '70px', opacity: 1 }
}

const transition = {
	duration: 0.5, ease: [0.27, 0.4, 0.3, 1]
}

function openInNewTab(url) {
	window.open(url, '_blank').focus();
}

export default function Forum({ screenWidth, page, online, setForum, forum, animate }) {
	const navigate = useNavigate()
	
	const [ sections, setSections ] = useState(null)
	const [ top, setTop ] = useState(null)
	const [ readLevel, setReadLevel ] = useState(0)
	
	const getReadLevel = () => {
		const profile = JSON.parse(localStorage.getItem("profile") ?? '{}')
		return profile?.settings?.staff || (profile.settings ? 1 : 0)
	}
	
	useEffect(() => {
		API.get_last_replies().then(({ sections, top }) => {
			console.log(top)
			setSections(sections)
			setTop(JSON.parse(top))
			setReadLevel(getReadLevel())
		})
	}, [])
	
	if(sections === null || page !== 'sections') return <></>
	
	return (forum && sections?.length ? <>
	<div className="sections">
	<motion.div 
	initial={{ opacity: 0 }}
	animate={{ opacity: 1 }}
	className="section-bar">
		<a>
		{
			<HoverableSections forum={forum} array={[
				[ "/forum", "Разделы" ]
			]}/>
		}
		</a>
	</motion.div>
	{
	forum.themes.map((theme, index) => {
		// Пропускаем, если нет доступных разделов
		if(!theme.sections.filter(x => x.rules.read <= readLevel).length) return;
		
		return <motion.div
			initial={{ transform: 'translate(30px)', opacity: 0 }}
			animate={{ transform: 'translate(0px)', opacity: 1 }}
			exit=   {{ transform: 'translate(30px)', opacity: 0 }}
			transition={{ delay: 0.1 }}
			className="theme">
				<div className={`theme-header theme-${index+1}`}>
				<a>{ theme.name }</a>
				</div>
				{ theme.sections.map((section) => {
					const uri = `/forum/${section.id + 1}-${section.name.toLowerCase().replace(/ /g,'-')}/`
					
					const go = e => {
						console.log(e)
						e.preventDefault(); navigate(uri)
					}
					
					const rules = sections[section.id].rules
					if(rules.read > readLevel) return;
					
					const stats = sections[section.id].stats
					const active = sections[section.id].active
					
					let activeURI
					if(active) activeURI = `/forum/topic/${active.topicId}-${active.title.toLowerCase().replace(/ /g,'-')}`
					
					return <>
					<div className="section">
						<div className="title-stats">
							<img
							onMouseDown={ go }
							className="icon" src={ChatIcon}/>
							<motion.a 
							whileHover={{ scale: 1.05 }}
							whileTap={{ scale: 0.95 }}
							onMouseDown={ go } onClick={ e => { e.preventDefault() } } 
							href={uri}
							className="section-title">{ section.name }
							</motion.a>
							<div className="left">
								<div className="stat">
									<a className={`top theme-text-${index + 1}`}>Тем</a>
									<a className="value">{ stats.topics }</a>
								</div>
								<div className="stat">
									<a className={`top theme-text-${index + 1}`}>Ответов</a>
									<a className="value">{ stats.posts }</a>
								</div>
							</div>
						</div>
						{
						active ? (
							<motion.div
							onClick={ e => { e.preventDefault(); navigate(activeURI) } }
							whileTap={{ scale: 0.97 }}
							className="last-reply"><div className="column">
								<a href={activeURI} className="title">{ active.title }</a>
								<div className="replier">Автор: { active.author.name },&nbsp;
								<a className="replied">{ ParseTime(active.createdAt) }</a></div>
							</div>
							<div
							style={{ 
								background: `url("https://awzone.net/user/avatar/${active.author.id}.jpg"), #777`,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat'
							}}
							className="avatar"/>
							<a className="mobile-replied-at">{ ParseTime(active.createdAt) }</a>
							</motion.div>
						) : <div className="last-reply"/>
						}
					</div>
					{ 
						theme.sections.length !== (index+1) ? (<div className="row-hr"/>) : <></> 
					}
					</>
				}) }
		</motion.div>
	})}
	</div>
	<div className="info sections-info">
		{online ? <InfoBlock index={2}  online={online} type={"online"}/> : '' }
		{top ?    <InfoBlock index={3}  top={top}       type={"top-topics"} 
							 navigate={navigate} animate={animate} sections={sections}/> : '' }
		<InfoBlock  index={4}
					style={{ padding: '15px 20px' }}
					header="Социальные сети"
					content={<div className="socials">
							<img src={Discord} onClick={() => openInNewTab("https://discord.gg/MC6ccff")}/>
							<img src={VK} onClick={() => openInNewTab("https://vk.com/artworld")}/>
							<img src={Telegram} onClick={() => openInNewTab("https://t.me/rezaneteam")}/>
						</div>}/>
	</div>
	</> : <></>)
}