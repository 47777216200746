import { motion } from "framer-motion"

import Fire from './img/fire.png'

export default function InfoBlock({ index, type, top, filter, online, style, header, content, footer, navigate, animate, sections }) {
	if(type === 'online') {
		const guests = (filter ? online.guests.filter(x => x.href === filter) : online.guests).length
		const users = (filter ? online.users.filter(x => x.href === filter) : online.users)
		style = { padding: '20px 20px' }
		header = filter ? "Страницу просматривают" : "Пользователи онлайн"
		content = <div className="font-palette">{users.length ? users.map(x=>x.name).join(', ') : 'Никого'}</div>
		/* Не забываем определить корректное окончание! */
		footer = <p>{"А также " + guests + " гост" + 
				( guests === 1 ? 'ь' : guests > 1 && guests < 5 ? 'я' : 'ей' )}</p>
	} else if(type === 'top-topics') {
		style = { padding: '20px 20px 0' }
		header = "Горячие темы"
		content = <>{
			!top.length ? <div className="top-topic font-palette">Ничего нет!</div> :
			top.sort((x,y) => y.newReplies - x.newReplies).map((topic, index) => {
				const url = `/forum/topic/${topic.topicId}-${topic.title.toLowerCase().replace(/ /g,'-')}`
				const section = sections.find(x => x.id === topic.section)
				
				return <motion.div
				onClick={e => {
					e.preventDefault()
					navigate(url)
				}}
				onHoverStart={e => {
					const el = e.target.querySelector("#insides")
					animate(el, { transform: 'translateX(5px)' })
				}}
				onHoverEnd={e => {
					const el = e.target.querySelector("#insides")
					animate(el, { transform: 'translateX(0px)' })
				}}
				className="top-topic">
				<div id="insides" className="column font-palette">
					<a href={url} className="title-row">{ topic.title }</a>
					<a className="section-row">В разделе: { section.name }</a>
				</div>
				<div id="icon" className="icon">
					<motion.img
					animate={{ rotate: [-5, 5, -5], scale: [1, 1.03, 1] }}
					transition={{ repeat: Infinity, duration: 1.5, delay: 0.4 * index }}
					src={Fire}/>
					<a>{ topic.newReplies }</a>
				</div>
			</motion.div>})
		}</>
		console.log(content)
	}
	
	return (
	<motion.div
		initial={{ opacity: 0, left: 100 }}
		animate={{ opacity: 1, left: 0 }}
		transition={{ delay: index * 0.1 }}
		className="online info-block">
		<header className="info-block-topper colorful-border border-2">
		<a>{header}</a>
		</header>
		<div
		style={style}
		className="info-block-content">
			{content}
		</div>
		{ footer !== undefined ? 
			<footer className="info-block-footer">
			<a>{footer}</a>
			</footer>
		 : "" }
	</motion.div>
	)
}